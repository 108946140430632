import { Button, MobileStepper, useTheme } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { setActiveStep } from "redux/reducers/walletSlice";
import { useAppDispatch, useAppSelector } from "redux/store";


export default function LinearStepper() {
  const theme = useTheme();
  const { activeStep } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  const handleNext = () => {
    dispatch(setActiveStep(1));
  };

  const handleBack = () => {
    dispatch(setActiveStep(0));
  };

  return (
    <MobileStepper
      style={{ marginTop: "20px" }}
      className="custom-stepper"
      variant="dots"
      steps={2}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          style={{visibility: "hidden"}}
          onClick={handleNext}
          disabled={activeStep === 1 }
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={handleBack}
          style={{visibility: "hidden"}}
          disabled={activeStep === 0 }
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </Button>
      }
    />
  );
}
