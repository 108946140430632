import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center'
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold'
  },
  description: {
    fontSize: '18px',
    fontWeight: 'normal',
    margin: '10px 0',
  },
  dateContainer: {
    fontSize: '25px',
    margin: '10px 0',
  },
  date: {
    fontWeight: 'bold'
  },
  time: {
  }
}));
