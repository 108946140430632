import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  info: {
    marginBottom: 20,
    textAlign: 'center'
  },
  error: {
    marginTop: 20,
    color: 'red',
    textAlign: 'center',
  },
}));
