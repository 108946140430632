import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { atcb_init } from "add-to-calendar-button";
import { ErrorMessage, Formik } from "formik";
import { countries } from "helpers/general";
import { getSignature } from "helpers/wallet";
import { useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  requestPhoneVerification,
  setActionErrors, setActiveStep, setCheckTerms, setErrorSubscription, setPhoneCode,
  setPhoneNumber, setTxHash, subscribeUser
} from "redux/reducers/walletSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import * as yup from "yup";
import "yup-phone";
import Modal from "../Modal";
import LinearStepper from "./LinearStepper";
import { useStyles } from "./styles";

const validationSchema = yup.object({
  phone: yup.string().required("Phone is required"),
  agreed: yup.boolean().isTrue("You must agree to the terms and conditions"),
});

interface ErrorResponse {
  statusCode: number;
  message: string[];
  error: string;
}

const Phone = () => {
  const {
    actionErrors,
    address,
    txHash,
    activeStep,
    errorSubscription,
    checkTerms,
    eventConfig,
  } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const [TCopen, setTCopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expireInMinutes, setExpireInMinutes] = useState(0);

  const setPhone = async (value: string) => {
    dispatch(setPhoneNumber(value));
  };
  const styles = useStyles();
  const _handlePhoneCheck = async (values: { phone: string }) => {
    setPhone(values.phone);
    setLoading(true);
    try {
      const result = await dispatch(
        requestPhoneVerification({ phone: `+${values.phone}` })
      ).unwrap();
      dispatch(setActiveStep(1));
      setExpireInMinutes(result.expireInMinutes);
      setLoading(false);
    } catch (error) {
      const res = error as ErrorResponse;
      dispatch(setActionErrors([res.message]));
      setLoading(false);
    }
  };

  const _handleSubscribe = async (values: {
    phone: string;
    phoneCode: string;
  }) => {
    const signature = await getSignature();
    setPhoneCode(values.phoneCode);

    setLoading(true);
    try {
      const txHash = await dispatch(
        subscribeUser({
          phone: `+${values.phone}`,
          address,
          phoneCode: values.phoneCode,
          signature: signature,
        })
      ).unwrap();

      dispatch(setTxHash(txHash));
      setLoading(false);
      atcb_init();
    } catch (error) {
      if (error && error === "Token already taken") {
        dispatch(setErrorSubscription(error));
      } else {
        dispatch(setActionErrors([error]));
      }

      setLoading(false);
    }
  };

  const _handleFormSubmit = async (values: {
    phone: string;
    phoneCode: string;
    country: string;
  }) => {
    if (activeStep === 0) {
      _handlePhoneCheck(values);
    } else {
      _handleSubscribe(values);
    }
  };

  return (
    <div>
      <div className={styles.address}>
        Your MetaMask address is {address.substring(0, 15) + "..."}
      </div>
      <Formik
        initialValues={{
          country: "ma",
          phoneCode: "",
          phone: "",
          agreed: false,
        }}
        validationSchema={validationSchema}
        onSubmit={_handleFormSubmit}
      >
        {({
          handleSubmit,
          values,
          setFieldTouched,
          setFieldValue,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
        }) =>
          txHash.length === 0 ? (
            errorSubscription === "Token already taken" ? (
              <>
              <Typography variant="h6" align="center" component="p">
                You already have a NFT token registered to your MetaMask address. You
                can already join the metaverse loft by clicking <a className={styles.here} href={process.env.REACT_APP_METAVERSE_LINK} target="_blank" rel='noreferrer noopener'>HERE</a> or add the event in your calendar
              </Typography>
              <div className={styles.center}>
                <div className="atcb" style={{ display: "none" }}>
                  {JSON.stringify(eventConfig)}
                </div>
              </div>
              </>
            ) : (
              <form onSubmit={handleSubmit} className={styles.form}>
                <FormControl
                  className={activeStep === 0 ? styles.show : styles.hide}
                >
                  <FormLabel className={styles.formLabel}>Phone :</FormLabel>
                  <ReactPhoneInput
                    country={values.country}
                    onlyCountries={countries}
                    onChange={(value) => {
                      setFieldValue("phone", value);
                      //reset errors
                      dispatch(setActionErrors([]));
                    }}
                    onBlur={() => setFieldTouched("phone", true)}
                    inputStyle={{ width: "100%" }}
                  />
                  <FormHelperText className={styles.error}>
                    <ErrorMessage name="phone" />
                  </FormHelperText>

                  {actionErrors.map((errorMsg, index) => (
                    <FormHelperText className={styles.error} key={index}>
                      {errorMsg}
                    </FormHelperText>
                  ))}

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ backgroundColor: "#4854b4" }}
                    disabled={
                      address.length === 0 ||
                      values.phone.length === 0 ||
                      !checkTerms ||
                      loading
                    }
                  >
                    {loading ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      "Send"
                    )}
                  </Button>

                  <FormControlLabel
                    className={styles.termsLabel}
                    control={
                      <Checkbox
                        name="agreed"
                        onChange={(e) => {
                          dispatch(setCheckTerms(e.target.checked));
                          setFieldValue("agreed", e.target.checked);
                        }}
                        onBlur={handleBlur}
                        style={{ color: "white " }}
                      />
                    }
                    label={
                      <div>
                        I do agree with{" "}
                        <span
                          className={styles.TC}
                          onClick={(e) => {
                            e.preventDefault();
                            setTCopen(true);
                          }}
                        >
                          Terms of Use
                        </span>
                      </div>
                    }
                  />
                </FormControl>
                <FormHelperText className={styles.error}>
                  <ErrorMessage name="agreed" />
                </FormHelperText>

                <Modal open={TCopen} handleClose={() => setTCopen(false)}>
                  Use of this site is governed by the <a className={styles.links} href="https://consensys.net/terms-of-use/" target="_blank" rel="noreferrer noopener">Terms of Use</a>. By using this site you accept and agree to these Terms.
                </Modal>

                <FormControl
                  className={activeStep === 1 ? styles.show : styles.hide}
                >
                  <Typography variant="h6" align="center" component="p">
                    We've sent a verification code to your phone
                    <br />+{values.phone}
                    <br/> Code will be expired in {expireInMinutes} mins
                  </Typography>
                  <div className="custom-styles">
                    <ReactInputVerificationCode
                      length={6}
                      onChange={(value) => {
                        setFieldValue("phoneCode", value);
                        dispatch(setActionErrors([]));
                      }}
                    />
                  </div>

                  {actionErrors.map((errorMsg, index) => (
                    <FormHelperText className={styles.errorCenter} key={index}>
                      {errorMsg}
                    </FormHelperText>
                  ))}

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ backgroundColor: "#4854b4", marginTop: "20px" }}
                    disabled={!Number(values.phoneCode) || loading}
                  >
                    {loading ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      "Subscribe"
                    )}
                  </Button>
                </FormControl>

                <LinearStepper />
              </form>
            )
          ) : (
            <>
              <Typography variant="h6" align="center" component="p">
                Congratulations, we sent the NFT Ticket to your address. You
                can now join the metaverse loft by clicking <a className={styles.here} href={process.env.REACT_APP_METAVERSE_LINK} target="_blank" rel='noreferrer noopener'>HERE</a> or add
                the event in your calendar

              </Typography>
              <div className={styles.center}>
                <div className="atcb" style={{ display: "none" }}>
                  {JSON.stringify(eventConfig)}
                </div>
              </div>
            </>
          )
        }
      </Formik>
    </div>
  );
};

export default Phone;
