import { Divider } from '@material-ui/core';
import { ComponentProps } from 'react';
import { useStyles } from './styles';

type Props = Pick<ComponentProps<'div'>, 'className'>;

const ImageDivider = ({ className }: Props) => {
  const styles = useStyles();

  return <Divider className={[styles.divider, className].join(' ')} orientation="vertical" flexItem />;
}

export default ImageDivider
