import { CircularProgress, Container } from "@material-ui/core";
import { ComponentProps, PropsWithChildren, ReactNode, useEffect } from "react";
import { requestPlatformStatus } from "redux/reducers/walletSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { useStyles } from "./styles";

interface Props {
  withInfo?: boolean;
  header?: ReactNode;
}

const MainText = (props: ComponentProps<"div">) => {
  return (
    <div {...props}>
      <p>Get your Access Ticket NFT and be part of the first Metaverse Experience.</p>
      <p>Watch the metaverse in a virtual loft, get access to augmented match data,
      share the match with remote people you care about and comment goals,
      missed chances, woodworks and tricks, but mainly: socialize with other
      fans.</p>
    </div>
  );
};

const Info = () => {
  const styles = useStyles();

  return (
    <div className={styles.info}>
      <div className={styles.iframeContainer}>
        <div className={styles.infoTitle}>
          New to MetaMask? Follow this tutorial to join Web3 revolution:
        </div>
        <iframe
          className={styles.iframe}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/pJ3hJ6JNKbg"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
};

const MainDialog = ({ children,  withInfo, header }: PropsWithChildren<Props>) => {
  const styles = useStyles();

  const { requestLoading, platformStatus } = useAppSelector(
    (state) => state.wallet
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    const callStatus = async () => {
      await dispatch(requestPlatformStatus());
    };
    callStatus();
  }, [dispatch]);

  const _displayPlatformStatus = () => {
    if (platformStatus !== "OPENED") {
      return (
        <div className={styles.errorTitle}>Platform is not yet opened!</div>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div className={styles.container} id="main-container">
      <div className={styles.title}>“The Metaverse Experience”</div>
      {requestLoading ? (
        <div className={styles.loading}>
          <CircularProgress className={styles.title} color="secondary" />
        </div>
      ) : (
        <> {_displayPlatformStatus()}
          { withInfo ? (
          <Container className={styles.withInfoContainer}>
            <div className={styles.mainContainerHalf}>
              {header}
              <MainText className={styles.mainText} />
              <div className={styles.childrenContainer}>{children}</div>
            </div>
            <Info />
          </Container>
          ) : (
          <Container className={styles.mainContainer}>
            <MainText className={styles.mainText} />
            <div id="custom-header" className={styles.childrenContainer}>
              {children}
            </div>
          </Container>
        )}
        </>
      )}
    </div>
  );
};

export default MainDialog;
