
import { createTheme, ThemeProvider } from "@material-ui/core";
import Home from "pages/home";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00197d",
      contrastText: "#fff",
    }
  },
  typography: {
    fontFamily: [
      'DIN Next LT Pro Condensed',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    fontSize: 14,
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Home />
      </div>
    </ThemeProvider>
  );
}

export default App;
