import { useStyles } from './styles';
import ImageDivider from 'ui/components/ImageDivider';
import Partners from 'ui/components/Partners';

const Header = () => {
  const styles = useStyles();

  return (
    <div className={styles.container} id="main-header">
      <Partners.SerieATim className={[styles.image, styles.firstImage].join(' ')} />
      <ImageDivider className={styles.firstDivider} />
      <Partners.Consensys className={styles.image} />
      <ImageDivider />
      <Partners.Nemesis className={styles.image} />
    </div>
  )
}

export default Header
