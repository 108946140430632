import { ComponentProps } from 'react';
import { useStyles } from './styles';

interface Props extends ComponentProps<'a'> {}

const SerieATim = ({className, ...props}: Props) => {
  const styles = useStyles();

  return (
    <a className={[className, styles.link].join(' ')} {...props} href="https://www.legaseriea.it" target="_blank" rel='noreferrer noopener'>
      <img draggable="false" className={styles.img} src="/seriea-tim.png" alt="Serie A Tim" />
    </a>
  )
}

const SerieATimColor = ({className, ...props}: Props) => {
  const styles = useStyles();

  return (
    <a className={[className, styles.link].join(' ')} {...props} href="https://www.legaseriea.it" target="_blank" rel='noreferrer noopener'>
      <img draggable="false" className={styles.img} src="/seriea-tim-color.png" alt="Serie A Tim" />
    </a>
  )
}

const Consensys = ({className, ...props}: Props) => {
  const styles = useStyles();

  return (
    <a className={[className, styles.link].join(' ')} {...props} href="https://consensys.net/nft/" target="_blank" rel='noreferrer noopener'>
      <img draggable="false" className={styles.img} src="/consensys.png" alt="Consensys" />
    </a>
  )
}

const Nemesis = ({className, ...props}: Props) => {
  const styles = useStyles();

  return (
    <a className={[className, styles.link].join(' ')} {...props} href="https://thenemesis.io/" target="_blank" rel='noreferrer noopener'>
      <img draggable="false" className={styles.img} src="/nemesis.png" alt="Nemesis" />
    </a>
  )
}

const Partners = { SerieATim, SerieATimColor, Consensys, Nemesis };

export default Partners;
