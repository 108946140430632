import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  divider: {
    backgroundColor: theme.palette.primary.contrastText,
    marginTop: 25,
    marginBottom: 25,
    width: 2
  }
}));
