import { PropsWithChildren } from 'react';
import { Modal as MuiModal, Box } from '@material-ui/core';
import { useStyles } from './styles';

interface Props extends PropsWithChildren<{}> {
  open?: boolean;
  handleClose?: () => void;
}

const Modal = ({ open, handleClose, children }: Props) => {
  const styles = useStyles();

  return (
    <MuiModal
      open={open || false}
      onClose={handleClose}
    >
      <Box className={styles.box}>
        {children}
      </Box>
    </MuiModal>
  );
}

export default Modal
