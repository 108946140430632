import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  address: {
    textAlign: 'center',
    margin: '20px 0',
    fontSize: '18px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 20,
    height: '100%',
    '& > button': {
      marginTop: 20
    },
    '& > label': {
      margin: '10px 0'
    }
  },
  formLabel: {
    color: 'white',
  },
  phoneInput: {
    width: '100%',
    margin: '20px 0',
  },
  checkBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  termsLabel: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    color: 'red',
    fontSize:'25px',
    textAlign: 'center',
  },
  errorCenter: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontSize:'18px',
  },
  center: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none',
  },
  TC: {
    textDecoration: 'underline',
  },
  here: {
    textDecoration: 'underline',
    color: 'white',
  },
  links: {
    textDecoration: 'underline',
    color: 'black',
  }
}));
