import { PropsWithChildren } from 'react';
import Footer from "../Footer";
import Header from "../Header";
import { useStyles } from './styles';

function Layout({ children }: PropsWithChildren<{}>) {
  const styles = useStyles();

  return (
    <div className={styles.layout}>
      <Header />
      { children }
      <Footer />
    </div>
  );
}

export default Layout;
