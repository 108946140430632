import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  layout: {
    width: '100%',
    //backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  }
}));
