import { Toolbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import ImageDivider from 'ui/components/ImageDivider';
import Partners from 'ui/components/Partners';
import { useStyles } from './styles';
import Modal from 'ui/components/Modal';
import { useState } from 'react';

function Social() {
  const styles = useStyles();

  return (
    <div className={styles.socialContainer}>
      <a href={process.env.REACT_APP_DISCORD_LINK} target="_blank" rel="noreferrer noopener">
        <img draggable="false" className={styles.socialImages} src="/discord.png" alt="Discord" />
      </a>
      <a href={process.env.REACT_APP_TELEGRAM_LINK} target="_blank" rel="noreferrer noopener">
        <img draggable="false" className={styles.socialImages} src="/telegram.png" alt="Telegram" />
      </a>
      <a href={process.env.REACT_APP_MAILTO} rel="noreferrer noopener">
        <img draggable="false" className={styles.socialImages} src="/mail.png" alt="Mail" />
      </a>
    </div>
  )
}

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Do you need help? Get in touch!
      </div>
      <Social />
      <div className={styles.partenersContainer}>
        <Partners.SerieATimColor className={styles.serieATim} />
        <div className={styles.text}>
          In partnership with:
        </div>
        <div className={styles.partners}>
          <Partners.Consensys className={styles.partnerImages} />
          <ImageDivider />
          <Partners.Nemesis className={styles.partnerImages} />
        </div>
      </div>
      <AppBar position="relative" color="default">
        <Toolbar>
          <div className={styles.footerTextLeft}>
            Powered by ConsenSys NFT | © ConsenSys Software Inc. 2022
          </div>
          <span className={styles.footerLink} onClick={() => setModalOpen(true)}>How do we use your data?</span>
        </Toolbar>
      </AppBar>
      <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        Any personal data you submit will be collected in accordance with the <a className={styles.links} href="https://consensys.net/privacy-policy/" target="_blank" rel="noreferrer noopener">ConsenSys Privacy Policy</a>
      </Modal>

    </div>
  );
}

export default Footer;
