import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance as axios } from "services/axiosInstance";
import { formatError } from "helpers/general";

interface WalletBody {
  phoneCode: string;
  phone: string;
  address: string;
  signature: string;
}

interface EventConfig {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string; //add-to-calendar component always use string for date and time.
  endTime: string; //add-to-calendar component always use string for date and time.
  options: any;
  trigger: string;
  iCalFileName: string;
  timeZone: string;
}

const PREFIX = "wallet";

const initialState = {
  error: "",
  address: "",
  phone: "",
  phoneCode: "",
  isLoading: false,
  errorSubscription: "",
  actionErrors: [] as string[],
  activeStep: 0,
  txHash: "",
  checkTerms: false,
  platformStatus: undefined,
  eventConfig: {} as EventConfig,
  requestLoading: true,
};

export const subscribeUser = createAsyncThunk<
  any,
  WalletBody,
  {
    rejectValue: string;
  }
>(`${PREFIX}`, async (body, thunkAPI) => {
  try {
    const { data } = await axios.post("/subscribe", {
      account: body.address,
      phoneNumber: body.phone,
      phoneCode: body.phoneCode,
      signature: body.signature,
    });
    return data;
  } catch (err) {
    const error = formatError(err);
    return thunkAPI.rejectWithValue(error);
  }
});

export const requestPhoneVerification = createAsyncThunk(
  "/check",
  async (body: { phone: string }, thunkAPI) => {
    try {
      const response = await axios.post("/check", {
        phoneNumber: body.phone,
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const requestPlatformStatus = createAsyncThunk(
  "/status",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/status", {});
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const walletSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.address = action.payload;
      state.isLoading = false;
    },
    setWalletLoading: (state, action) => {
      state.isLoading = action.payload || !state.isLoading;
    },
    setWalletError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    setPhoneNumber: (state, action) => {
      state.phone = action.payload;
      state.isLoading = false;
    },
    setPhoneCode: (state, action) => {
      state.phoneCode = action.payload;
      state.isLoading = false;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
      state.isLoading = false;
    },
    setActionErrors: (state, action) => {
      state.actionErrors = action.payload;
      state.isLoading = false;
      state.requestLoading = false;
    },
    setCheckTerms: (state, action) => {
      state.checkTerms = action.payload;
      state.isLoading = false;
    },
    setTxHash: (state, action) => {
      state.txHash = action.payload;
      state.isLoading = false;
    },
    setErrorSubscription: (state, action) => {
      state.errorSubscription = action.payload;
      state.isLoading = false;
    },
    setPlatformStatus: (state, action) => {
      state.platformStatus = action.payload;
      state.isLoading = false;
    },
    setRequestLoading: (state, action) => {
      state.requestLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestPlatformStatus.fulfilled, (state, { payload }) => {
      state.platformStatus = payload.platformStatus;
      state.eventConfig = _formatEventConfig(payload.event);
      state.requestLoading = false;
    });
    builder.addCase(requestPlatformStatus.rejected, (state, action) => {
      state.actionErrors = ['You cannot access this service from your country'];
      state.requestLoading = false;
    });
  },
});

const _formatEventConfig = (eConfig: any) => {
  eConfig.options = [
    "Google",
    "Microsoft365",
    "MicrosoftTeams",
    "Outlook.com",
    "Yahoo",
    "iCal",
  ];
  eConfig.endDate = eConfig.startDate;
  eConfig.trigger = "click";
  eConfig.iCalFileName = "REminder-Event";
  return eConfig;
};

export const {
  setWalletAddress,
  setWalletLoading,
  setWalletError,
  setPhoneNumber,
  setPhoneCode,
  setActiveStep,
  setActionErrors,
  setCheckTerms,
  setTxHash,
  setErrorSubscription,
  setRequestLoading,
} = walletSlice.actions;

export default walletSlice.reducer;
