import MainDialog from "ui/components/MainDialog";
import Layout from "ui/layouts/Layout";
import Phone from "ui/components/phone";
import Metamask from "ui/components/metamask";
import { useAppSelector } from "redux/store";
import FeaturedActivity from "ui/components/FeaturedActivity";

function Home() {

  const { platformStatus, address, actionErrors } = useAppSelector((state) => state.wallet);
  return (
    <div>
      <Layout>
        {actionErrors[0] === "You cannot access this service from your country" ? (
          <h2 style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', color: 'white'}}>You cannot access this service if you are not from MENA region</h2>
        ) : (
        <MainDialog withInfo={!address} header={<FeaturedActivity />}>
          <div> { platformStatus === 'OPENED' && address ? (
            <Phone />
          ) : (
            <Metamask />
          )}</div>
        </MainDialog>
        )}
      </Layout>
    </div>
  );
}

export default Home;
