import { useStyles } from "./styles";
import "../../../css/atcb.css";
import { useAppSelector } from "redux/store";

const FeaturedActivity = () => {
  const styles = useStyles();

  const formatDate = (dateString: string) => {
    //The regex below is to use to fix Iphone and safari issue.
    const newDate = new Date(dateString.replace(/-/g, "/"));
    return newDate.toLocaleDateString([]);
  };

  const farmatTime = (time: string) => {
    //The regex below is to use to fix Iphone and safari issue.
    const newDate = new Date(`${eventConfig.startDate} ${time}`.replace(/-/g, "/"));
    return newDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const { eventConfig } = useAppSelector(
    (state) => state.wallet
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{eventConfig.name}</div>
      <div className={styles.description}>{eventConfig.description.replace("[url]https://thenemesis.io/@serieA[/url]", "")}</div>
      <div className={styles.dateContainer}>
        Live on{" "}
        <span className={styles.date}>{formatDate(eventConfig.startDate)}</span>
      </div>
      <div className={styles.time}>
        {farmatTime(eventConfig.startTime)} - {farmatTime(eventConfig.endTime)}{" "}
        - {eventConfig.timeZone}
      </div>
    </div>
  );
};

export default FeaturedActivity;
