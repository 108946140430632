import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 200
  },
  title: {
    textAlign: 'center',
    fontSize: '30px',
    marginBottom: 50,
    fontWeight: 'bold'
  },
  loading: {
    textAlign: 'center',
    fontSize: '25px',
    color: 'white',
    fontWeight: 'bold'
  },
  errorTitle: {
    textAlign: 'center',
    fontSize: '25px',
    color: 'red',
    marginBottom: 20,
    fontWeight: 'bold'
  },
  infoTitle: {
    fontSize: '25px',
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  withInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width: '50%',
  },
  mainContainerHalf: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  info: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 50
    }
  },
  mainText: {
    width: '60%',
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  iframeContainer: {
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  iframe: {
    width: '100%'
  },
  childrenContainer: {
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));
