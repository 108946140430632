import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 100,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    }
  },
  image: {
    width: '30%',
  },
  firstImage: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  firstDivider: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));
