import Button from "@material-ui/core/Button";
import { useAppDispatch, useAppSelector } from "redux/store";
import { formatError } from "helpers/general";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  setWalletAddress,
  setWalletError,
  setWalletLoading,
} from "redux/reducers/walletSlice";
import { getMetaMask } from "helpers/wallet";
// @ts-ignore
import OpenApp from "react-open-app";
import { useEffect } from "react";
import { useStyles } from "./styles";

const Metamask = () => {
  const dispatch = useAppDispatch();
  const { platformStatus, isLoading, error } = useAppSelector(
    (state) => state.wallet
  );
  const connectWallet = async () => {
    try {
      const ethereum = getMetaMask();
      dispatch(setWalletLoading(true));
      dispatch(setWalletError(null));
      await ethereum.request({
        method: "eth_requestAccounts",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      dispatch(setWalletLoading(false));
    } catch (err: any) {
      if (err.code === 4001) {
        dispatch(setWalletError("User reject connections"));
      } else {
        const error = formatError(err);
        dispatch(setWalletError(error));
      }
    }
  };

  const handleMetaMaskAccountChange = () => {
    const ethereum = getMetaMask();
    ethereum.on("accountsChanged", function (accounts: Array<string>) {
      dispatch(setWalletAddress(accounts[0] ?? ""));
      if (!accounts[0]) setWalletError("");
    });
  };

  const checkIfAccountIsConnected = async () => {
    const ethereum = getMetaMask();
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });
    dispatch(setWalletAddress(accounts[0] || ""));
  };

  useEffect(() => {
    try {
      getMetaMask();
      handleMetaMaskAccountChange();
      checkIfAccountIsConnected();
    } catch (err) {
      dispatch(setWalletError(formatError(err)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const styles = useStyles();
  return (
    <div className={styles.buttonContainer}>
      <div className={styles.info}>
        Available only for metamask users in the MENA region:
      </div>
      {platformStatus === "OPENED" ? (
        <>
          <Button
            onClick={connectWallet}
            color="primary"
            variant="outlined"
            style={{ backgroundColor: "#FFFFFF", color: "#4854b4" }}
          >
            {isLoading && <CircularProgress />}&nbsp;
            {error && error !== "User reject connections" ? (
              <>
                <OpenApp
                  href="https://metamask.io/download/"
                  android="https://metamask.app.link/dapp/metaverse.legaseriea.it"
                  ios="https://metamask.app.link/dapp/metaverse.legaseriea.it"
                >
                  {error}
                </OpenApp>
              </>
            ) : (
              "Connect Wallet"
            )}
          </Button>
          <span className={styles.error}>
            {error && error === "User reject connections" ? error : ""}
          </span>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Metamask;
