export const errors = {
  server: "Something went wrong, please try again later",
  noNetwork: "Connection error, please try again later",
  transaction: {
    noId: "Transaction failed, please try again later",
    notConnected: "Wallet not connected",
  },
  metaMask: {
    alreadyPending: "Pease check the extension",
    rejected: "Connection rejected",
    default: "MetaMask Error",
  },
};
