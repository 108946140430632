import {ethers} from 'ethers';
export const getMetaMask = () => {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { ethereum } = window as any;
  if (!ethereum) {
    throw new Error("Use MetaMask");
  }

  return ethereum;
};

export const getProvider = () => {
  const provider = new ethers.providers.Web3Provider(getMetaMask());
  return provider;
};


export const getSignature = async () => {
  const signer = getProvider().getSigner();
  const signature = await signer.signMessage(process.env.REACT_APP_SIGNATURE_MESSAGE || '');
  return signature;
}
