import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 'auto'
  },
  socialContainer: {
    width: 500,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  socialImages: {
    height: 50,
    [theme.breakpoints.down('xs')]: {
      height: 40
    }
  },
  text: {
    fontSize: 20,
    textAlign: 'center'
  },
  serieATim: {
    width: '35%',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: '35%'
    }
  },
  partenersContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '100%'
  },
  partners: {
    width: 500,
    display: 'flex',
    justifyContent: 'center',
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  partnerImages: {
    width: 250,
    [theme.breakpoints.down('xs')]: {
      width: 150
    }
  },
  footerTextLeft: {
    flex: 1,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em'
    },
  },
  footerLink: {
    color: '#000',
    textDecoration: 'none',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em'
    },
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  links: {
    textDecoration: 'underline',
    color: 'black',
  }
}));
